<template>
  <v-card flat>
    <v-row>
      <v-col cols="12" lg="6">
        <h3>Unterschrift: {{ type }} <i v-if="!!name">({{ name }})</i></h3>
        <div v-if="!image && !disabled" class="mt-4">
          <v-btn @click="save" class="mr-8">Speichern</v-btn>
          <v-btn @click="clear">Löschen</v-btn>
        </div>
      </v-col>
      <v-col cols="12" lg="6">
        <div :style="{display:image||disabled?'none':'block'}">
          <canvas width="500px" height="150px" style="border:1px solid rgb(128,128,128);" ref="signaturePad" disabled />
        </div>
        <img v-if="image" :src="image" style="width:500px;height:150px;border:1px solid rgb(128,128,128);" />
        <div v-else-if="disabled" style="width:500px;height:150px;border:1px solid rgb(128,128,128);background:rgb(220,220,220);"></div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SignaturePad from 'signature_pad'

export default {
  name: 'unterschrift',

  props: {
    image: {
      type: String,
      default: null
    },
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    pad: null
  }),

  methods: {
    save () {
      if (!this.pad) return
      const data = this.pad.toDataURL('image/png')
      const isEmpty = this.pad.isEmpty()
      if (!isEmpty) this.$emit('save', data)
    },
    clear () {
      if (!this.pad) return
      this.pad.clear()
    }
  },

  mounted () {
    if (this.$refs.signaturePad) {
      this.pad = new SignaturePad(this.$refs.signaturePad, {
        throttle: 0,
        minDistance: 1
      })
    } else {
      this.pad = null
    }
  }
}
</script>

<style scoped>

</style>
